<template>
  <div class="px-10">
    <v-toolbar dense flat class="mb-10">
      <div class="text-h4 primary--text">คูปองส่วนลด</div>
      <div style="width: 400px;">
        <v-text-field
          class="ml-10"
          v-model="filter.start_with"
          prepend-inner-icon="mdi-magnify"
          label="ค้นหา รหัสคูปอง"
          outlined
          rounded
          dense
          single-line
          hide-details
          @keyup="getData"
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined small class="ml-5" @click="add()"
        ><v-icon left small>mdi-plus</v-icon>เพิ่มคูปองส่วนลด</v-btn
      >
    </v-toolbar>

    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by="id"
      :loading-text="$table_loading_text"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      item-key="_id"
      @click:row="edit"
    >
      <template v-slot:[`item.index`]="{ item }">
        {{
          list
            .map(function(x) {
              return x._id;
            })
            .indexOf(item._id) + 1
        }}
      </template>
      <template v-slot:[`item.end_date`]="{ item }">
        {{ item.end_date | moment($formatDate) }}
      </template>
      <template v-slot:[`item.product_type`]="{ item }">
        {{ item.product_type == '0' ? 'รายเดือน' : '' }}
        {{ item.product_type == '1' ? 'รายปี' : '' }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: true,
    pageCount: 0,
    headers: [
      { text: 'ลำดับ', value: 'index', align: 'center' },
      { text: 'รหัสคูปอง', value: 'code', align: 'center' },
      { text: 'รายละเอียดของส่วนลด', value: 'detail', align: 'center' },
      { text: 'วันหมดอายุ', value: 'end_date', align: 'center' },
      { text: 'อายุการใช้งาน (เดือน)', value: 'limit', align: 'center' },
      { text: 'ดำเนินการ ', value: 'actions', align: 'center' }
    ],
    list: [],
    filter: {
      item_per_page: 20,
      page: 1,
      start_with: ''
    }
  }),

  created() {
    this.getData();
  },

  methods: {
    add() {
      this.$router.push({ name: 'backoffice-coupon-create' });
    },
    edit(item) {
      this.$router.push({
        name: 'backoffice-coupon-update',
        params: { id: item._id }
      });
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true
        })
      };
      await this.$axios
        .post(`${this.$baseUrl}/backend/coupon/get_coupon`, body)
        .then(res => {
          this.list = res.result.data;
          console.log(this.list);
          this.pageCount = res.result.total_page || 1;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },

    remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign({ coupon_id: item._id }, this.$privateKey, {
                noTimestamp: true
              })
            };

            await this.$axios
              .post(`${this.$baseUrl}/backend/coupon/delete_coupon`, body)
              .then(res => {
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch(err => {
                this.$alertServerError({ title: res.error_message });
              });
          }
        }
      );
    },

    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },
    phoneNumberFormat(val) {
      if (val) {
        return val.substring(0, 3) + '-' + val.substring(3, val.length);
      } else return val;
    }
  }
};
</script>
